import { useMutation, useQueryClient } from '@tanstack/vue-query';
import type { UserDashboardStartChallengeResponseType } from '@magma-app/magma-types/user-dashboard/challenge';
import { instances } from '@/services/api';

export const useStartChallenge = () => {
	return useMutation({
		mutationFn: async (challengeId: number) => {
			const response = await instances.userDashboard.post(
				`challenges/${challengeId}/start`
			);
			return response.data as UserDashboardStartChallengeResponseType;
		},
	});
};
