import { useQuery } from '@tanstack/vue-query';
import { instances } from '@/services/api';
import type { UserDashboardGetPublicSignUpHelpeeImportResponseType } from '@magma-app/magma-types/user-dashboard/public';

export const useHelpeeData = (uuid?: string) => {
	return useQuery({
		queryKey: ['helpee-complete', uuid],
		queryFn: async () => {
			const response = await instances.main.get(
				`/user-dashboard/public/sign-up-helpee-form/from-import?uuid=${uuid}`
			);

			return response.data as UserDashboardGetPublicSignUpHelpeeImportResponseType;
		},
		enabled: !!uuid,
		retry: 0,
		staleTime: Infinity,
	});
};
