import { instances } from '@/services/api';
import { useQuery } from '@tanstack/vue-query';
import type { UserDashboardGetAllChallengesResponseType } from '@magma-app/magma-types/user-dashboard/challenge';

export const useChallenges = () => {
	return useQuery({
		queryKey: ['challenges'] as const,
		queryFn: async () => {
			const response = await instances.userDashboard.get('challenges');
			return response.data as UserDashboardGetAllChallengesResponseType;
		},
		retry: 1,
		staleTime: Infinity,
	});
};
