import { useMutation, useQueryClient } from '@tanstack/vue-query';
import type { UserDashboardValidateChallengeRequestType } from '@magma-app/magma-types/user-dashboard/challenge';
import { instances } from '@/services/api';

export const useAddProof = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data: UserDashboardValidateChallengeRequestType) => {
			const formData = new FormData();
			if (data.photoProof) formData.append('photoProof', data.photoProof);
			if (data.textProof) formData.append('textProof', data.textProof);
			if (data.linkProofUrl) formData.append('linkProofUrl', data.linkProofUrl);

			const response = await instances.userDashboard.put(
				`challenges/${data.userChallengeId}/proof`,
				formData
			);
			return response.data;
		},
		onSuccess: () => {
			queryClient.refetchQueries(['challenges'] as any);
		},
	});
};
