// NPM
import { defineStore } from 'pinia';

// Types
import type { IChallenge } from '@/types/IChallenge';

// Services
import Api from '@/services/api';

// Stores
import { useUserStore } from '@/stores/user';

export const useChallengesStore = defineStore('challenges', {
	state: () => {
		return {
			availableChallenges: [] as IChallenge[],
			completedChallenges: [] as IChallenge[],
			uncompletedChallenges: [] as IChallenge[],
			displayCompletedChallengeDetails: false,
			displayChallengeDetails: false,
		};
	},

	actions: {
		setCompletedChallengeDetails(displayCompletedChallengeDetails: boolean) {
			this.displayCompletedChallengeDetails = displayCompletedChallengeDetails;
		},

		setChallengeDetails(displayChallengeDetails: boolean) {
			this.displayChallengeDetails = displayChallengeDetails;
		},

		async getChallenges() {
			const response = await Api.userDashboard().challenges.getChallenges();

			this.completedChallenges = response.data.complete;
			this.uncompletedChallenges = response.data.incomplete;
			this.availableChallenges = response.data.available;
		},

		async accomplishChallenge(userChallengeId: number) {
			const response =
				await Api.userDashboard().challenges.accomplishChallenge(
					userChallengeId
				);

			const challengeIndex = this.uncompletedChallenges.findIndex(
				(challenge) => {
					return challenge.userChallengeId === userChallengeId;
				}
			);

			this.uncompletedChallenges.splice(challengeIndex, 1);
			this.completedChallenges.push(response.data);

			if (
				response.data.status === 'validatedByAdmin' ||
				response.data.status === 'done'
			) {
				const userStore = useUserStore();
				const userBalance = userStore.user?.balance + response.data.points;
				userStore.updateUserBalance(userBalance);
			}
		},

		async startChallenge(challengeId: number) {
			const response =
				await Api.userDashboard().challenges.startChallenge(challengeId);

			const challengeIndex = this.uncompletedChallenges.findIndex(
				(challenge) => {
					return challenge.challengeId === challengeId;
				}
			);

			this.uncompletedChallenges.splice(challengeIndex, 1, response.data);
		},
	},
});
