// NPM
import Api from '@/services/api';
import { defineStore } from 'pinia';

export const useCommonStore = defineStore('common', {
	state: () => {
		return {
			adminModal: {
				props: {} as { [key: string]: any },
			},
			alert: {
				props: {},
			},
			callTextModal: {
				props: {} as { [key: string]: any },
			},
			manageChallengesRewardsModal: {
				props: {} as { [key: string]: any },
			},
			manageRelationModal: {
				props: {} as { [key: string]: any },
			},
			reportModal: {
				props: {} as { [key: string]: any },
			},
			shareModal: {
				props: {} as { [key: string]: any },
			},
			slideOverModal: {
				props: {} as { [key: string]: any },
			},
			touchpointsModal: {
				props: {} as { [key: string]: any },
			},
			qrCodeModal: {
				props: {} as { [key: string]: any },
			},
		};
	},

	actions: {
		setAdminModal(props?: { [key: string]: any }) {
			this.adminModal.props = props || {};
		},

		setAlert(props?: { [key: string]: any }) {
			this.alert.props = props || {};

			// Reset alert after 1.5 seconds
			setTimeout(() => this.resetAlert(), props?.duration || 3000);
		},

		resetAlert() {
			this.alert = {
				props: {},
			};
		},

		setCallTextModal(props?: { [key: string]: any }) {
			this.callTextModal.props = props || {};
		},

		setManageChallengesRewardsModal(props?: { [key: string]: any }) {
			this.manageChallengesRewardsModal.props = props || {};
		},

		setManageRelationModal(props?: { [key: string]: any }) {
			this.manageRelationModal.props = props || {};
		},

		setReportModal(props?: { [key: string]: any }) {
			this.reportModal.props = props || {};
		},

		setShareModal(props?: { [key: string]: any }) {
			this.shareModal.props = props || {};
		},

		setSlideOverModal(props?: { [key: string]: any }) {
			this.slideOverModal.props = props || {};
		},

		setTouchpointsModal(props?: { [key: string]: any }) {
			this.touchpointsModal.props = props || {};
		},

		setQrCodeModal(props?: { [key: string]: any }) {
			this.qrCodeModal.props = props || {};
		},

		closeAdminModal() {
			this.adminModal.props.open = false;
		},

		closeCallTextModal() {
			this.callTextModal.props.open = false;
		},

		closeManageChallengesRewardsModal() {
			this.manageChallengesRewardsModal.props.open = false;
		},

		closeManageRelationModal() {
			this.manageRelationModal.props.open = false;
		},

		closeReportModal() {
			this.reportModal.props.open = false;
		},

		closeShareModal() {
			this.shareModal.props.open = false;
		},

		closeSlideOverModal(closeType?: string) {
			if (closeType) {
				this.slideOverModal.props.closeType = closeType;
			}
			this.slideOverModal.props.open = false;
		},

		closeTouchpointsModal() {
			this.touchpointsModal.props.open = false;
		},

		closeQrCodeModal() {
			this.qrCodeModal.props.open = false;
		},

		async testError() {
			await Api.main().test.testError();
		},
	},
});
