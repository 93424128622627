<script setup lang="ts">
// NPM
import { ref } from 'vue';
import { useMq } from 'vue3-mq';

// Components
import {
	BaseCard,
	BaseEmptyState,
	Modal,
	BaseButton,
	BaseBadge,
	FieldFileUploader,
	FieldInput,
} from '@magma-app/magma-lapilli';

// API
import { useChallenges } from '@/api/challenges/use-challenges';
import { useAddProof } from '@/api/challenges/use-add-proof';
import { reactive } from 'vue';

// Plugins
const mq = useMq();

const { data: challenges, isLoading } = useChallenges();
const { mutate: addProof } = useAddProof();

const modal = ref<number | null>(null);
</script>

<template>
	<div
		v-if="!isLoading"
		class="block h-full flex-1 px-4 md:px-8"
		:class="{
			'content-start lg:flex lg:flex-wrap': challenges?.rejected.length,
		}"
	>
		<template v-if="challenges?.rejected.length">
			<Modal
				v-for="challenge in challenges?.rejected"
				:key="challenge.userChallengeId"
				:open="modal === challenge.challengeId"
				@open-focus="(e) => e.preventDefault()"
				content-class="max-w-[90dvw] sm:max-w-[480px]"
				@open-change="
					(open) => {
						modal = challenge.challengeId;

						if (!open) {
							modal = null;
						}
					}
				"
			>
				<template #trigger>
					<BaseCard
						aria-hidden="true"
						class="mb-2 lg:mb-6 lg:mr-6"
						:title="challenge.name"
						:description="challenge.description"
						:img-url="
							challenge.pictureUrl
								? challenge.pictureUrl
								: mq.lgPlus
									? '/img/challenge_lg.png'
									: '/img/challenge.png'
						"
						:points="challenge.points"
						:badge-status="{ points: challenge.points, type: 'challenge' }"
						:display-points="!challenge.pictureUrl"
					/>
				</template>
				<template #content>
					<div>
						<div
							class="w-12 h-12 bg-[#F1F5F9] rounded-full border-8 border-[#F8FAFC] flex items-center justify-center mb-5"
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
									stroke="#5F6C85"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
						<h3 class="text-mgm-txt-lg font-semibold text-foreground-emphasis">
							{{ challenge.name }}
						</h3>
						<BaseBadge
							class="mt-2"
							size="md"
							:text="challenge.points"
							icon-type="icon"
							icon-name="star"
						/>
						<div
							v-html="challenge.description"
							class="text-mgm-txt-sm text-foreground-default my-5 max-h-[40dvh] lg:max-h-auto overflow-y-auto"
						></div>
						<BaseButton
							size="lg"
							disabled
							class="!bg-background-danger-default w-full !text-foreground-danger-default border-none"
							>{{ $t('challenges.rejected.cta') }}</BaseButton
						>
					</div>
				</template>
			</Modal>
		</template>

		<BaseEmptyState
			v-else
			class="flex h-full flex-col items-center justify-center"
			:text="$t('challengesView.empty.all')"
			type="empty-list"
		/>
	</div>
</template>
