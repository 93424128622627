<script setup lang="ts">
// NPM
import { ref } from "vue";
import { useMq } from "vue3-mq";

// Components
import {
  BaseCard,
  BaseEmptyState,
  Modal,
  BaseBadge,
  BaseButton,
  BaseLoader,
} from "@magma-app/magma-lapilli";

// API
import { useChallenges } from "@/api/challenges/use-challenges";
import { useStartChallenge } from "@/api/challenges/use-start-challenge";

// Plugins
const mq = useMq();

const { data: challenges, isLoading, refetch } = useChallenges();
const { mutate: startChallenge } = useStartChallenge();

// Reactive variables
const modal = ref<number | null>(null);
const currentModalStep = ref<"start" | "confirm" | "end">("start");
</script>

<template>
  <div
    v-if="!isLoading"
    class="block h-full flex-1 px-4 md:px-8"
    :class="{
      'content-start lg:flex lg:flex-wrap': challenges?.available.length,
    }"
  >
    <template v-if="challenges?.available.length">
      <Modal
        v-for="(challenge, challengeIndex) in challenges?.available"
        :key="challengeIndex"
        :open="modal === challenge.challengeId"
        @open-focus="(e) => e.preventDefault()"
        content-class="max-w-[90dvw] sm:max-w-[480px]"
        @open-change="
          (open) => {
            modal = challenge.challengeId;

            if (!open) {
              modal = null;
              currentModalStep = 'start';
              refetch();
            }
          }
        "
      >
        <template #trigger>
          <BaseCard
            class="mb-2 lg:mb-6 lg:mr-6"
            :title="challenge.name"
            :description="challenge.description"
            :img-url="
              challenge.pictureUrl
                ? challenge.pictureUrl
                : mq.lgPlus
                  ? '/img/challenge_lg.png'
                  : '/img/challenge.png'
            "
            :points="challenge.points"
            :badge-status="{ points: challenge.points, type: 'challenge' }"
            :display-points="!challenge.pictureUrl"
          />
        </template>
        <template #content>
          <div v-if="!isLoading">
            <div v-if="currentModalStep === 'start'">
              <div
                class="w-12 h-12 bg-[#F1F5F9] rounded-full border-8 border-[#F8FAFC] flex items-center justify-center mb-5"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                    stroke="#5F6C85"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h3
                class="text-mgm-txt-lg font-semibold text-foreground-emphasis"
              >
                {{ challenge.name }}
              </h3>
              <BaseBadge
                class="mt-2"
                size="md"
                :text="challenge.points"
                icon-type="icon"
                icon-name="star"
              />
              <div
                v-html="challenge.description"
                class="text-mgm-txt-sm text-foreground-default my-5 max-h-[40dvh] lg:max-h-auto overflow-y-auto"
              ></div>
              <BaseButton
                size="lg"
                class="w-full"
                @click="currentModalStep = 'confirm'"
                >{{ $t("challenges.available.start.cta") }}</BaseButton
              >
            </div>
            <div v-if="currentModalStep === 'confirm'">
              <div
                class="w-12 h-12 bg-[#F1F5F9] rounded-full border-8 border-[#F8FAFC] flex items-center justify-center mb-5"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                    stroke="#5F6C85"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h3
                class="text-mgm-txt-lg font-semibold text-foreground-emphasis"
              >
                {{ $t("challenges.available.confirm.title") }}
              </h3>
              <p
                class="text-mgm-txt-sm text-foreground-default my-5 overflow-hidden"
              >
                {{
                  $t("challenges.available.confirm.content", {
                    email: challenge.contactAdminEmail,
                  })
                }}
              </p>
              <div class="flex gap-2">
                <BaseButton
                  size="lg"
                  class="flex-1"
                  state="scdr"
                  @click="
                    () => {
                      currentModalStep = 'start';
                      modal = null;
                    }
                  "
                  >{{ $t("generic.cancel") }}</BaseButton
                >
                <BaseButton
                  size="lg"
                  class="flex-1"
                  @click="
                    () => {
                      startChallenge(challenge.challengeId);
                      currentModalStep = 'end';
                    }
                  "
                  >{{ $t("challenges.available.confirm.cta") }}</BaseButton
                >
              </div>
            </div>
            <div v-if="currentModalStep === 'end'">
              <div
                class="w-12 h-12 bg-[#D1FADF] rounded-full border-8 border-[#ECFDF3] flex items-center justify-center mb-5"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                    stroke="#03874C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <h3
                class="text-mgm-txt-lg font-semibold text-foreground-emphasis"
              >
                {{ $t("challenges.available.end.title") }}
              </h3>
              <ul
                class="list-disc pl-0 text-mgm-txt-sm text-foreground-default my-4"
              >
                <li>
                  {{
                    $t("challenges.available.end.content.first", {
                      email: challenge.contactAdminEmail,
                    })
                  }}
                </li>
                <li>
                  {{ $t("challenges.available.end.content.second") }}
                </li>
              </ul>
              <BaseButton
                size="lg"
                class="w-full"
                @click="
                  () => {
                    currentModalStep = 'start';
                    modal = null;
                    refetch();
                  }
                "
                >{{ $t("generic.close") }}</BaseButton
              >
            </div>
          </div>
          <BaseLoader v-else />
        </template>
      </Modal>
    </template>

    <BaseEmptyState
      v-else
      class="flex h-full flex-col items-center justify-center"
      :text="$t('challengesView.empty.all')"
      type="empty-list"
    />
  </div>
</template>
