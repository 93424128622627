<script setup lang="ts">
// NPM
import { onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

// Components
import { BaseBadge, BaseLoader, FieldTabs } from '@magma-app/magma-lapilli';
import TheSidebar from '@/components/shell/TheSidebar.vue';

// Stores
import { useChallengesStore } from '@/stores/challenges';
import { useUserStore } from '@/stores/user';
const challengesStore = useChallengesStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { t } = useI18n({ useScope: 'global' });

// Data
const tabs = [
	{
		label: t('challenges.status.available'),
		value: 'available',
	},
	{
		label: t('challenges.status.ongoing'),
		value: 'ongoing',
	},
	{
		label: t('challenges.status.validated'),
		value: 'validated',
	},
	{
		label: t('challenges.status.rejected'),
		value: 'rejected',
	},
];

// Reactive variables
const currentTab = ref<'available' | 'ongoing' | 'validated' | 'rejected'>(
	route.name === 'ongoing-challenges'
		? 'ongoing'
		: route.name === 'validated-challenges'
			? 'validated'
			: route.name === 'rejected-challenges'
				? 'rejected'
				: 'available'
);
const initialized = ref(false);

// Lifecycle hooks
onBeforeMount(async () => {
	initialized.value = true;
});
</script>

<template>
	<div class="relative flex bg-background-subtle">
		<TheSidebar />

		<div
			class="flex w-full flex-1 flex-col bg-background-default pt-2 lg:ml-20 lg:mt-3 lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:pt-8 2xl:ml-64"
			:class="{
				'pb-14':
					!challengesStore.displayChallengeDetails &&
					!challengesStore.displayCompletedChallengeDetails,
			}"
		>
			<div
				class="mb-4 flex items-center justify-between px-4 md:px-8 lg:mb-8"
				:class="[
					challengesStore.displayChallengeDetails ||
					challengesStore.displayCompletedChallengeDetails
						? 'hidden lg:flex'
						: 'flex',
				]"
			>
				<h2 class="text-2xl font-bold leading-7 text-foreground-emphasis">
					Challenges
				</h2>

				<BaseBadge
					:text="userStore.user?.balance ? userStore.user?.balance : 0"
					size="md"
					icon-type="icon"
					icon-name="star"
				/>
			</div>

			<FieldTabs
				v-model="currentTab"
				class="mx-4 mb-3 flex justify-start md:mx-8 lg:mb-6"
				:class="[
					challengesStore.displayChallengeDetails ||
					challengesStore.displayCompletedChallengeDetails
						? 'hidden lg:flex'
						: 'flex',
				]"
				:tabs="tabs"
				@update:model-value="
					currentTab === 'available'
						? router.push({ name: 'available-challenges' })
						: currentTab === 'ongoing'
							? router.push({ name: 'ongoing-challenges' })
							: currentTab === 'validated'
								? router.push({ name: 'validated-challenges' })
								: router.push({ name: 'rejected-challenges' })
				"
			/>

			<routerView v-if="initialized" class="flex-1" />

			<BaseLoader v-else class="min-h-screen" />
		</div>
	</div>
</template>
