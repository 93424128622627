<script setup lang="ts">
import { animate, spring } from 'motion';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useHelpeeData } from '@/api/complete-helpee-signup/use-helpee-data';

const route = useRoute();

const { data } = useHelpeeData(
	route.params.campaignHelpeeUuid as string | undefined
);
const indexImgSize = [
	'w-10',
	'w-14',
	'w-12',
	'w-10',
	'w-14',
	'w-12',
	'w-10',
	'w-14',
];

// transform indexImgPostion to array of object
const indexImgAnimation = [
	{ top: ['50%', '20%'], left: ['50%', '20%'], opacity: [0, 0, 1] },
	{ top: ['50%', '30%'], right: ['50%', '15%'], opacity: [0, 0, 1] },
	{ top: ['50%', '45%'], left: ['50%', '34%'], opacity: [0, 0, 1] },
	{ bottom: ['50%', '22%'], left: ['50%', '22%'], opacity: [0, 0, 1] },
	{ bottom: ['50%', '14%'], right: ['50%', '18%'], opacity: [0, 0, 1] },
];

const { t } = useI18n();

onMounted(() => {
	data.value?.signUpInformation?.helpersProfilePicture?.forEach(
		(_, index): any => {
			const imgEl = document.querySelector(
				`.onboarding-user-photo:nth-child(${index + 1})`
			) as HTMLElement;
			animate(imgEl, indexImgAnimation[index], {
				duration: 6,
				delay: 0.1 * index,
				easing: spring(),
			});
		}
	);
});
</script>

<template>
	<div class="flex flex-col h-full flex-1 gap-0 md:gap-4 md:mt-0">
		<div
			class="w-full aspect-square bg-[url('/img/illustration_helper_onboarding_background.svg')] bg-contain bg-no-repeat bg-center relative h-[336px]"
		>
			<img
				v-for="(img, index) in data?.signUpInformation?.helpersProfilePicture"
				:src="img"
				:class="`${indexImgSize[index]} onboarding-user-photo h-auto rounded-full shadow-md border border-white absolute transition-all duration-200 -translate-y-1/2`"
			/>
		</div>

		<div class="px-6">
			<h1 class="text-mgm-txt-lg text-foreground-emphasis text-center mb-3">
				{{ $t('importProspects.finish.title') }}
			</h1>
			<p class="text-mgm-txt-sm text-foreground-default text-center mb-3">
				{{ $t('importProspects.finish.subtitle') }}
			</p>
			<p
				class="text-mgm-txt-sm text-foreground-default text-center mb-10 block"
			>
				{{ $t('importProspects.finish.end') }}
			</p>
		</div>
	</div>
</template>
